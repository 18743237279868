import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import showdown from 'showdown'
import ForumLogo from '../icons/forum-logo.svg'
import InstagramLogo from '../icons/instagram-logo.svg'
import TwitterLogo from '../icons/twitter-logo.svg'
import '../css/Footer.css'

const converter = new showdown.Converter()

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        markdownRemark(frontmatter: {footer_text: {regex: "^/"}}) {
          frontmatter {
            footer_text
          }
        }
      }
    `}
    render={data => (
      <footer className="footer">
        <div className="container">
          <div className="footer__first">
            <div className="footer__column">
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(
                    data.markdownRemark.frontmatter.footer_text
                  ),
                }}
              />
              <div className="footer__sn">
                <a href="https://twitter.com/NutriPDGMIT" target="_blank" rel="noreferrer">
                  <TwitterLogo />
                </a>
                <a href="https://www.instagram.com/nutripdgmit/" target="_blank" rel="noreferrer">
                  <InstagramLogo />
                </a>
              </div>
            </div>
            <div className="footer__column">
              <img src="../img/atu-logo.svg" alt="ATU Logo" />
              
              <ForumLogo />
            </div>
          </div>
          <div className="footer__second footer__navigation">
              <a href="/">Home</a>
              <a href="/about-the-framework">About the Framework</a>
              {/* <a href="/CBAFramework/">New Programme</a> */}
              <a href="/self-assessment/">Evaluate your Programme</a>
              <a href="/about-us">About Us</a>
              <a href="/resources">Resources</a>
              <a href="/contact/">Contact Us</a>
          </div>
        </div>
        
        <div className="container taCenter">
          <span>
            © Copyright {new Date().getFullYear()} All rights reserved. Crafted by{' '}
            <a href="https://www.annertech.com/">Annertech</a>.
          </span>
        </div>
      </footer>
    )}></StaticQuery>
)

export default Footer