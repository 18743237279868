import React from "react"
import '../css/global.css'
import Nav from "./Nav"
import Footer from "./Footer"
import SEO from "./Seo"

export default function Layout({ children }) {
  return (
    <>
    <SEO />
    <Nav />
      <div className="layout">
        {children}
      </div>
    <Footer />
    </>
  )
}